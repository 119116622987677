import React from 'react'

import { Grid } from '@material-ui/core'

export default function Col({ children, className, style = {} }) {
  style.flex = 1

  return (
    <Grid item className={className} style={style}>
      {children}
    </Grid>
  )
}
