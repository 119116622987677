import _ from 'lodash'
import React from 'react'
import { withSnackbar } from 'notistack'
import queryString from 'query-string'

import { makeStyles } from '@material-ui/core/styles'
import {
  Card,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'

import Button from '../../components/button'
import Col from '../../components/col'
import Divider from '../../components/divider'
import Organization from '../../backend/organization'
import Page from '../page'
import Campaign from '../../backend/campaign'
import Row from '../../components/row'
import Window from '../../utils/window'

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
    padding: theme.spacing(2),
  },
  card: {
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'wrap',
    padding: theme.spacing(2),
    borderRadius: 16,
  },
  vertical: {
    display: 'flex',
    flexFlow: 'column',
  },
  dropdown: {
    width: '100%',
  },
  image: {
    height: 250,
    width: 250,
    margin: theme.spacing(2),
  },
}))

function CreateProduct({ location }) {
  const classes = useStyles()

  const [ID, setID] = React.useState(null)
  const [helperText, setHelperText] = React.useState('Create')
  const [orgs, setOrgs] = React.useState('')
  const [types, setTypes] = React.useState('')

  const [org, setOrg] = React.useState('')
  const [type, setType] = React.useState('')
  const [images, setImages] = React.useState([])
  const [designerName, setDesignerName] = React.useState('')
  const [designerURL, setDesignerURL] = React.useState('')

  React.useEffect(() => {
    const URL_ID = _.get(queryString.parse(location.search), 'id')
    setHelperText(URL_ID ? 'Design' : 'Create')

    Campaign.get(URL_ID).then(product => {
      setID(product.ID)
      setOrg(product.organization ?? '')
      setType(product.type() ?? '')
      setImages(product.images())
      setDesignerName(product.designer().name ?? '')
      setDesignerURL(product.designer().url ?? '')
    })

    Organization.all().then(setOrgs)
    Campaign.types().then(setTypes)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function prompt() {
    Window.prompt('Insert image URL').then(url => {
      setImages(_.concat(images, url))
    })
  }

  function save() {
    try {
      validate()

      const product = new Campaign({
        ID,
        creator: null,
        organization: org,
        details: {
          designer: {
            name: designerName,
            url: designerURL,
          },
          images,
          type,
        },
      })
      product.update()
    } catch (error) {
      Window.alert(error.message, { variant: 'error' })
    }
  }

  function validate() {
    if (org === '') throw new Error('Please choose who has access')
    if (type === '') throw new Error('Please choose product type')
    if (images.length === 0) throw new Error('Please upload images')
    if (
      (designerName !== '' && designerURL === '') ||
      (designerName === '' && designerURL !== '')
    )
      throw new Error('Please specify all designer information')
  }

  function content() {
    return (
      <Grid container className={classes.container}>
        <Grid item>
          <Card className={classes.card}>
            <Typography gutterBottom variant="h5" component="h2">
              {helperText} your Product
            </Typography>

            <Row spreadOut>
              <Col>Who has access</Col>
              <Col>
                <Select
                  value={org}
                  onChange={e => setOrg(e.target.value)}
                  className={classes.dropdown}
                >
                  {_.map(orgs, (organization, idx) => {
                    return (
                      <MenuItem key={idx} value={organization.ID}>
                        {organization.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </Col>
            </Row>

            <Divider />

            <Row spreadOut>
              <Col>Type</Col>
              <Col>
                <Select
                  value={type}
                  onChange={e => setType(e.target.value)}
                  className={classes.dropdown}
                >
                  {_.map(types, (productType, idx) => (
                    <MenuItem key={idx} value={productType}>
                      {productType}
                    </MenuItem>
                  ))}
                </Select>
              </Col>
            </Row>

            <Divider />

            <Row spreadOut>
              <Col>Images</Col>
              <Col className={classes.vertical}>
                <Grid container>
                  {_.map(images, (image, idx) => {
                    return (
                      <img
                        key={idx}
                        alt={image}
                        src={image}
                        className={classes.image}
                      />
                    )
                  })}
                </Grid>
                <Grid container style={{ flexFlow: 'row' }}>
                  <Button onClick={prompt}>Insert link</Button>
                  <Button disabled>Upload</Button>
                </Grid>
              </Col>
            </Row>

            <Divider />

            <Row spreadOut>
              <Col>Designer (optional)</Col>
              <Col className={classes.vertical}>
                <TextField
                  label="Name"
                  value={designerName}
                  onChange={e => setDesignerName(e.target.value)}
                />
                <TextField
                  label="Link"
                  value={designerURL}
                  onChange={e => setDesignerURL(e.target.value)}
                />
              </Col>
            </Row>

            <Row spreadOut>
              <Col>
                <Button color="cyan" onClick={save} variant="contained">
                  Save
                </Button>
              </Col>
            </Row>
          </Card>
        </Grid>
      </Grid>
    )
  }

  return <Page content={content()} title={`${helperText} | Made to Uplift`} />
}

export default withSnackbar(CreateProduct)
